import React from "react";
import { Outlet } from "react-router";
import logo from "../Assets/arabi/logo.png";
import arabicLogo from "../Assets/arabi/logoArabi.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Logo = styled.img`
  margin-left: 60px;
  @media (max-width: 640px) {
    margin-left: 20px;
  }
`;

const Header = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <section className="mainer">
        <main className="main">
          <div className="nav">
            <div className="navbar-header">
              <Link to="/">
                <Logo
                  src={i18n.language === "ar" ? arabicLogo : logo}
                  alt="logo"
                  className="logo"
                />
              </Link>
            </div>
          </div>
          <Outlet />
        </main>
      </section>
    </>
  );
};

export default Header;
