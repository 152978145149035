import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../ProductPage/productPage.css";
import FilterPage from "../ProductPage/filterPage";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  Drawer,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import ProductCard from "./ProductCard";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { countries } from "countries-list";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartByUuidAsync,
  addToCartWithAuthenticationAsync,
} from "../../core/slices/cartSlice";
import { useAuth } from "../../hooks/useAuth";
import { service } from "../../core/services";
import {
  clearProducts,
  getProductsByCategoryAsync,
} from "../../core/slices/productSlice";
import InfiniteScroll from "react-infinite-scroll-component";

const countriesArrWithIs = Object.keys(countries).map((country) => ({
  ...countries[country],
  code: country,
}));

const countriesArr = countriesArrWithIs.filter(
  (el) => el.name.toLowerCase() !== "israel"
);

const AddButton = styled(Button)`
  background: #000;
  color: #fff;
  padding: 15px;
  width: 100%;
  border-radius: 0;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const SortText = styled.p`
  padding: 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

function ProductPage() {
  // const [productsData, setProductsData] = useState({});
  const [productsDataa, setProductsDataa] = useState({});
  const { categoryId } = useParams();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [sort, setSort] = useState("date-new-to-old");
  const [showFilter, setShowFilter] = useState(false);
  const [isCountryOpen, setCountryOpen] = useState(false);
  const [isAddContactOpen, setAddContact] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const dispatch = useDispatch();
  const { companyInformation } = useSelector((state) => state.company);
  const { productsData, status } = useSelector((state) => state.product);
  const { accessToken } = useAuth();
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Lebanon",
    native: "لبنان",
    phone: [961],
    continent: "AS",
    capital: "Beirut",
    currency: ["LBP"],
    languages: ["ar", "fr"],
    code: "LB",
  });

  useEffect(() => {
    if (companyInformation?.currency === "AED") {
      setSelectedCountry({
        name: "United Arab Emirates",
        native: "دولة الإمارات العربية المتحدة",
        phone: [971],
        continent: "AS",
        capital: "Abu Dhabi",
        currency: ["AED"],
        languages: ["ar"],
        code: "AE",
      });
    }
  }, [companyInformation?.currency]);

  const [filters, setFilters] = useState(null);

  const { i18n, t } = useTranslation();

  const handleFilterClick = () => {
    setShowFilter(true);
    document.body.classList.add("no-scroll");
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
    document.body.classList.remove("no-scroll");
  };

  const applyFilters = (selectedFilters) => {
    dispatch(clearProducts());
    setFilters(selectedFilters);
    setPage(1);
  };

  // const fetchProducts = useCallback(async () => {
  //   try {
  //     setFetching(true);
  //     setLoading(true);
  //     const apiUrl = `products/${categoryId}?page=${page}&sort=${sort}`;

  //     const response = await service.get(apiUrl, {
  //       params: filters,
  //       headers: {
  //         "Accept-Language": i18n.language || "en",
  //       },
  //     });

  //     const newData = response.data.data;
  //     if (page === 1) {
  //       setProductsData({
  //         products: newData.products,
  //         product_count: newData.product_count,
  //         subcategory: newData.subcategory,
  //       });
  //     } else {
  //       setProductsData((prevData) => ({
  //         ...prevData,
  //         products: [...prevData.products, ...newData.products],
  //         subcategory: newData.subcategory,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching products: ", error);
  //   } finally {
  //     setLoading(false);
  //     setFetching(false);
  //   }
  // }, [categoryId, filters, i18n.language, page, sort]);

  useEffect(() => {
    dispatch(
      getProductsByCategoryAsync({
        page: 1,
        sort: sort,
        categoryId: categoryId,
        filters,
      })
    );
  }, [categoryId, dispatch, filters, sort]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await service.get(`color/${categoryId}`, {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setProductsDataa(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId, i18n.language]);

  const handleScroll = useCallback(() => {
    // setPage((prev) => prev + 1);
    // dispatch(
    //   getProductsByCategoryAsync({
    //     page: page,
    //     sort: sort,
    //     categoryId: categoryId,
    //     filters,
    //   })
    // );
    // if (
    //   window.innerHeight + document.documentElement.scrollTop !==
    //     document.documentElement.offsetHeight ||
    //   status === "loading"
    // ) {
    //   return;
    // } else {
    //   setPage((prev) => prev + 1);
    //   dispatch(
    //     getProductsByCategoryAsync({
    //       page: page,
    //       sort: sort,
    //       categoryId: categoryId,
    //       filters,
    //     })
    //   );
    // }
    if (
      status !== "loading" &&
      productsData.product_count !== productsData.products.length
    ) {
      if (
        window.innerHeight + Math.round(window.scrollY) + 1000 >=
        document.body.offsetHeight
      ) {
        setPage((prev) => prev + 1);
        dispatch(
          getProductsByCategoryAsync({
            page: page,
            sort: sort,
            categoryId: categoryId,
            filters,
          })
        );
      }
    }
  }, [categoryId, dispatch, filters, page, sort, status]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSortChange = (event) => {
    setSort(event.target.value);
    setPage(1);
  };

  const addToCartAsync = async () => {
    const uuid = localStorage.getItem("cart_uuid");
    setAddToCartLoading(true);
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: selectedProduct,
          quantity: 1,
          cart_uuid: uuid,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {
          setAddToCartLoading(false);
          setSelectedItem(null);
        }
      )
    );
  };

  const handleAddToCart = () => {
    if (!accessToken) {
      const phoneNumber = localStorage.getItem("phoneNumber");
      if (!phoneNumber) {
        setAddContact(true);
      } else {
        addToCartAsync();
      }
    } else {
      // const uuid = localStorage.getItem("cart_uuid");
      // setAddToCartLoading(true);
      // dispatch(
      //   addToCartWithAuthenticationAsync(
      //     {
      //       product_id: selectedProduct,
      //       quantity: 1,
      //       cart_uuid: uuid,
      //     },
      //     () => {
      //       setAddToCartLoading(false);
      //       setSelectedItem(null);
      //     },
      //     () => {
      //       setAddToCartLoading(false);
      //     }
      //   )
      // );
      addToCartAsync();
    }
  };

  return (
    // <div className="productPage">
    <>
      <Dialog
        open={isCountryOpen}
        onClose={() => setCountryOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ height: "100vh" }}>
          <Autocomplete
            disablePortal
            open
            options={countriesArr}
            fullWidth
            onChange={(even, value) => {
              setSelectedCountry(value);
              setCountryOpen(false);
            }}
            getOptionLabel={(option) => option.native}
            filterOptions={(options, { inputValue }) =>
              options.filter((el) => {
                console.log(el.name + el.native);
                return (el.name + el.native).toLowerCase().includes(inputValue);
              })
            }
            autoHighlight
            style={{ border: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option?.name === value?.name
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                gap={1}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.native} ({option.phone})
              </Box>
            )}
            renderInput={(params) => (
              <TextField style={{ border: "none" }} {...params} />
            )}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isAddContactOpen}
        onClose={() => setAddContact(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          textAlign={"center"}
          fontSize={14}
        >{t`Please enter your phone number`}</DialogTitle>
        <Box
          display={"flex"}
          alignItems={"center"}
          p={2}
          gap={1}
          flexDirection={"row"}
        >
          <Button color="inherit" onClick={() => setCountryOpen(true)}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${selectedCountry?.code?.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${selectedCountry?.code?.toLowerCase()}.png`}
                alt=""
              />
              <Typography>+{selectedCountry?.phone}</Typography>
            </Box>
          </Button>
          <TextField
            fullWidth
            type="text"
            id="phone_number"
            name="phone_number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={`${t`Phone number`} *`}
            required
          />
        </Box>
        <AddButton
          onClick={() => {
            localStorage.setItem(
              "phoneNumber",
              selectedCountry.phone + phoneNumber
            );
            handleAddToCart();
            setAddContact(false);
          }}
        >{t`Add`}</AddButton>
      </Dialog>
      <Container style={{ maxWidth: "87%" }}>
        <Drawer
          open={showFilter}
          anchor="right"
          onClose={() => setShowFilter(false)}
        >
          <Box width={{ xs: "100vw", md: "50vw" }}>
            <FilterPage
              onClose={handleCloseFilter}
              colors={productsDataa.unique_colors}
              sizes={productsDataa.unique_sizes}
              minPrice={productsDataa.min_price}
              maxPrice={productsDataa.max_price}
              applyFilters={applyFilters}
            />
          </Box>
        </Drawer>
        {/* <div
          className={`filter-overlay ${showFilter ? "show" : ""}`}
          onClick={handleCloseFilter}
        >
          <div
            className="`filter-container`"
            onClick={(e) => e.stopPropagation()}
          > */}

        {/* </div> */}
        {/* </div> */}

        <div className="subcategory-count">
          <p className="products-subcategory">
            {productsData.subcategory}{" "}
            <span className="products-count">
              {productsData.product_count} {t`PRODUCTS`}
            </span>
          </p>
          <div>
            <button
              className="products-filter-button"
              onClick={handleFilterClick}
            >
              {t`FILTERS`}
            </button>
            <span className="sort-label">{t`SORT BY`}:</span>
            <select
              name="sort"
              id="sort"
              className="sort-select"
              onChange={handleSortChange}
              value={sort}
            >
              <option value="date-new-to-old">{t`New To Old`}</option>
              <option value="date-old-to-new">{t`Old To New`}</option>
              <option value="price-high-to-low">{t`Price High To Low`}</option>
              <option value="price-low-to-high">{t`Price Low To High`}</option>
            </select>
          </div>
        </div>
        <SortText>{t`Sorting is according to the order of the season’s collection`}</SortText>
        {/* <InfiniteScroll
          dataLength={productsData?.products?.length || 0}
          next={handleScroll}
          hasMore={true} // Replace with a condition based on your data source
          loader={
            <Box
              height={productsData?.products?.length > 0 ? "500px" : "100vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress size={35} style={{ color: "#000" }} />
            </Box>
          }
          endMessage={<p>No more data to load.</p>}
        >
          <Grid
            container
            gap={1.5}
            minHeight={"100vh"}
            // alignItems={"center"}
            // justifyContent={"center"}
            mt={5}
          >
            {Array.isArray(productsData.products) &&
              productsData.products.map((product, index) => (
                <Grid xs={5.7} md={2.9}>
                  <ProductCard
                    key={product.id}
                    onAddToCart={(p) => handleAddToCart(p)}
                    addToCart={true}
                    product={product}
                    index={index}
                    selectedItem={selectedItem}
                    onSelectItem={(item) => setSelectedItem(item)}
                    addToCartLoading={addToCartLoading}
                    handleSelectedProduct={(p) => setSelectedProduct(p)}
                  />
                </Grid>
              ))}
          </Grid>
        </InfiniteScroll> */}

        <Grid
          container
          gap={1.5}
          // minHeight={"100vh"}
          // alignItems={"center"}
          // justifyContent={"center"}
          mt={5}
        >
          {Array.isArray(productsData.products) &&
            productsData.products.map((product, index) => (
              <Grid xs={5.7} md={2.9}>
                <ProductCard
                  key={product.id}
                  onAddToCart={(p) => handleAddToCart(p)}
                  addToCart={true}
                  product={product}
                  index={index}
                  selectedItem={selectedItem}
                  onSelectItem={(item) => setSelectedItem(item)}
                  addToCartLoading={addToCartLoading}
                  handleSelectedProduct={(p) => setSelectedProduct(p)}
                />
              </Grid>
            ))}
        </Grid>

        {status === "loading" && (
          <Box
            height={productsData?.products?.length > 0 ? "500px" : "100vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={35} style={{ color: "#000" }} />
          </Box>
        )}
      </Container>
    </>
  );
}

export default ProductPage;
