import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  faClose,
  faMinus,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartByUuidAsync,
  decrementFromCartAsync,
  getCartAsync,
  removeFromCartAsync,
} from "../../core/slices/cartSlice";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const ProductImage = styled.img`
  width: 200px;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 100px;
  }
`;

const RegularText = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const AddButton = styled(Button)`
  background: #000 !important;
  color: #fff !important;
  padding: 15px !important;
  width: 100%;
  &:hover {
    background: rgba(0, 0, 0, 0.9) !important;
  }
`;

const CartDrawer = ({ onClose, openCart }) => {
  const theme = useTheme();
  const mediumDevice = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { t } = useTranslation();

  const { status, cart, addToCartStatus } = useSelector((state) => state.cart);
  const { companyInformation } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCartAsync(user?.id));
  }, [dispatch, user?.id]);

  const handleIncrement = (item) => {
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: item.product_id,
          quantity: 1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {}
      )
    );
    // dispatch()
  };

  const handleDecrement = (item) => {
    if (item.quantity <= 1) {
      handleRemove(item);
      return;
    }
    dispatch(
      decrementFromCartAsync(
        {
          product_id: item.product_id,
          quantity: -1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {}
      )
    );
  };

  const handleRemove = (item) => {
    dispatch(removeFromCartAsync(item.id));
  };

  return (
    <Drawer
      open={openCart}
      onClose={onClose}
      anchor={mediumDevice ? "right" : "bottom"}
    >
      <Box
        p={{ xs: 2, sm: 1, md: 4 }}
        width={mediumDevice ? 450 : "100vw"}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        justifyContent={"space-between"}
      >
        <Box>
          <Box
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p className="p-text-bold">
              {t`Your Selection`} ({cart?.carts?.length || 0})
            </p>
            <IconButton size="small" onClick={onClose}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </Box>
          <Box
            gap={3}
            display={"flex"}
            overflow={"scroll"}
            minWidth={"100vw"}
            alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
            flexDirection={{ xs: "row", sm: "row", md: "column" }}
          >
            {cart?.carts?.map((item) => (
              <Box>
                <Box
                  width={"100vw"}
                  key={item.id}
                  display={"flex"}
                  gap={2}
                  flex={1}
                  flexDirection={"row"}
                >
                  <ProductImage src={item.image[0]} alt={"product"} />
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <p className="p-text-bold">{item.product_name}</p>
                      <RegularText>Color: {item.color}</RegularText>
                      <RegularText>Size: {item.size}</RegularText>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={"row"}
                        gap={1}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleDecrement(item)}
                        >
                          {addToCartStatus === "loading" ||
                          status === "loading" ? (
                            <CircularProgress
                              style={{ color: "#000" }}
                              size={18}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faMinus} />
                          )}
                        </IconButton>
                        <RegularText>Qty: {item.quantity}</RegularText>
                        <IconButton
                          size="small"
                          onClick={() => handleIncrement(item)}
                        >
                          {addToCartStatus === "loading" ||
                          status === "loading" ? (
                            <CircularProgress
                              style={{ color: "#000" }}
                              size={18}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </IconButton>
                      </Box>
                      <RegularText>
                        {item.computed_price}{" "}
                        {localStorage?.getItem("currency") ??
                          companyInformation?.default_currency}
                      </RegularText>
                    </Box>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleRemove(item)}
                    >
                      {status === "loading" ? (
                        <CircularProgress style={{ color: "#000" }} size={18} />
                      ) : (
                        <FontAwesomeIcon icon={faTrash} />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <p className="p-text-bold">{t`Subtotal`}</p>
            <p className="p-text-bold">
              {cart?.sub_total}{" "}
              {localStorage?.getItem("currency") ??
                companyInformation?.default_currency}
            </p>
          </Box>
          {cart?.carts?.length > 0 && (
            <AddButton
              LinkComponent
              onClick={() => {
                window.location.href = "/checkout";
              }}
            >
              {t`CHECKOUT`}
            </AddButton>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default CartDrawer;
