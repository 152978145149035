import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Section2/section2.css";
import { useTranslation } from "react-i18next";
import { service } from "../../../../core/services";

function SectionFour() {
  const [sectionFour, setSectionFour] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchSectionFour = async () => {
      try {
        const response = await service.get("sectionfour", {
          headers: {
            "Accept-Language": i18n.language || "en",
          },
        });
        setSectionFour(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSectionFour();
  }, [i18n.language]);

  const handleItemClick = (id) => {
    window.location.href = `/products/${id}`;
  };
  return (
    <div className="sectiontwo">
      {Array.isArray(sectionFour) &&
        sectionFour.map((item, index) => (
          <div className="sectiontwo-image-name" key={index}>
            {item.image && (
              <a
                key={item?.id}
                onClick={() => handleItemClick(item.id)}
                href={`/products/${item?.id}`}
                className="a-sections"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="sectiontwo-image"
                />
                <p className="sectiontwo-name">{item.name}</p>
              </a>
            )}
            {item.video && (
              <a href={`/products/${item.id}`} className="a-sections">
                <p className="section1-name">{item.name}</p>
                <video playsInline autoPlay loop muted className="banner-video">
                  <source src={item.video} type="video/mp4" />
                </video>
              </a>
            )}
          </div>
        ))}
    </div>
  );
}

export default SectionFour;
