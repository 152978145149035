import { service } from ".";

class CartApi {
  GetCartByUuid(uuid, promoCode, shipingPrice) {
    return service.get(
      `cartuuid/${uuid}?coupon-code=${promoCode}&shipping-rule-id=${shipingPrice}`
    );
  }
  GetCart(userId) {
    return service.get(`cart/${userId}`);
  }
  addToCartByUuid(body) {
    return service.post(`guestcart`, body);
  }
  addToCart(body) {
    return service.post(`cart`, body);
  }
  deleteFromCartByUuid({ uuid, itemId }) {
    return service.delete(`cart/${uuid}/${itemId}`);
  }
}

export const cartApi = new CartApi();
