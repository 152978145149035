import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { service } from "../../core/services";
import { Box } from "@mui/material";

const paymentMethodsData = [
  {
    id: 1,
    name: "Credit/Debit Card",
    value: "stripe",
  },
  {
    id: 2,
    name: "Cash on delivery",
    value: "cod",
  },
];

const PaymentMethod = ({
  stateId,
  country,
  onConfirm,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
}) => {
  const { t } = useTranslation();

  //   const [paymentMethodsData, setPaymentMethodsDta] = useState([
  //     {
  //       id: 1,
  //       name: "Credit/Debit Card",
  //       value: "stripe",
  //     },
  //     {
  //       id: 2,
  //       name: "Cash on delivery",
  //       value: "cod",
  //     },
  //   ]);

  // const fetchShiipingMethod = useCallback(async () => {
  //   try {
  //     const res = await service.get("payment-channel/" + stateId);
  //     // setPaymentMethodsDta(res.data.data);
  //     // setSelectedPaymentMethod(res.data.data[0]);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [stateId]);

  // useEffect(() => {
  //   fetchShiipingMethod();
  // }, [fetchShiipingMethod]);

  return (
    <div className="" style={{ padding: 20 }}>
      <h2
        className="email-h2"
        style={{
          fontSize: 17,
          marginBottom: 40,
        }}
      >{t`Payment Methods`}</h2>
      {country.title === "Lebanon" ? (
        <>
          {paymentMethodsData?.map((method) => (
            <>
              {/* {method.name === "Cash on delivery" && country.name === "Lebanon" && ( */}
              <Box
                display={"flex"}
                alignItems={"center"}
                pl={1}
                pr={1}
                style={{ cursor: "pointer" }}
                border={"1px solid #000"}
                mb={2}
                onClick={() => {
                  setSelectedPaymentMethod(method);
                }}
              >
                <Box
                  mr={1}
                  border={"2px solid #000"}
                  width={30}
                  height={30}
                  borderRadius={60}
                  p={0.5}
                >
                  {selectedPaymentMethod?.id === method.id && (
                    <Box
                      bgcolor={"#000"}
                      borderRadius={60}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </Box>
                <p className="p-text">{method?.name}</p>
              </Box>
              {/* )} */}
            </>
          ))}
        </>
      ) : (
        [
          {
            id: 1,
            name: "Credit/Debit Card",
          },
        ].map((method) => (
          <Box
            display={"flex"}
            alignItems={"center"}
            pl={1}
            pr={1}
            style={{ cursor: "pointer" }}
            border={"1px solid #000"}
            mb={2}
            onClick={() => {
              setSelectedPaymentMethod(method);
            }}
          >
            <Box
              mr={1}
              border={"2px solid #000"}
              width={30}
              height={30}
              borderRadius={60}
              p={0.5}
            >
              {selectedPaymentMethod?.id === method.id && (
                <Box
                  bgcolor={"#000"}
                  borderRadius={60}
                  width={"100%"}
                  height={"100%"}
                />
              )}
            </Box>
            <p className="p-text">{method?.name}</p>
          </Box>
        ))
      )}

      <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
        <button
          className="register-button"
          onClick={onConfirm}
          style={{ width: 200 }}
        >
          {t`Confirm`}
        </button>
      </Box>
    </div>
  );
};

export default PaymentMethod;
