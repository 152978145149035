import React, { useState } from "react";
import "../Footer/footer.css";
import { FaApplePay, FaArrowRight, FaGooglePay } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import ContactUs from "./contactus";
import {
  CreditCard,
  CreditCardSharp,
  Facebook,
  FacebookTwoTone,
  Instagram,
} from "@mui/icons-material";
import googlePlay from "../../Assets/googlePlay.png";
import appStore from "../../Assets/appStore.png";
import { service } from "../../core/services";
import { useSelector } from "react-redux";

function Footer() {
  const { t, i18n } = useTranslation();
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { companyInformation } = useSelector((state) => state.company);

  const subscribeToNewsLetter = async () => {
    try {
      setLoading(true);
      const res = await service.post("subscribe", {
        email,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setEmail("");
    }
  };

  return (
    <footer>
      <ContactUs onClose={() => setContactUsOpen(false)} open={contactUsOpen} />
      <main className="footer-main">
        <section className="column">
          <h5 className="footer-title">{t`Subscribe to newsletter`}</h5>
          <ul className="footer-ul">
            <div className="email-arrow">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={`${t`Email`} *`}
                required
                className="footer-email"
              />
              <IconButton
                onClick={subscribeToNewsLetter}
                disabled={loading}
                size="small"
              >
                {loading ? (
                  <CircularProgress style={{ color: "#000" }} size={14} />
                ) : (
                  <FaArrowRight className="arrow-icon" />
                )}
              </IconButton>
            </div>
            <p className="privacy-policy">
              {t`I have read and understood`}{" "}
              <span className="privacy-policy-span">
                <Link to="/privacy-policy">
                  {t`Kbilarabi's privacy policy`}{" "}
                </Link>
              </span>
              {t`and i agree to receive the newsletter and other marketting communication`}
              .
            </p>
          </ul>
        </section>

        <section className="column">
          <h5 className="footer-title">{t`Do you need help?`}</h5>
          <ul className="footer-ul">
            <li className="footer-li">
              <div onClick={() => setContactUsOpen(true)}>{t`Contact us`}</div>
            </li>
            <li className="footer-li">
              <a href="/">
                {i18n.language === "ar"
                  ? companyInformation?.location_ar
                  : companyInformation?.location_en}
                {/* {companyInformation?.location_en} */}
                {/* {t`KYVELI S.a.r.l. Sin el Fil, Horsh Tabet, Center ST. Georges, Beirut`} */}
              </a>
            </li>
          </ul>
        </section>

        {/* <section className="column">
          <h5 className="footer-title">Exclusive Services</h5>
          <ul className="footer-ul">
            <li className="footer-li">
              <a href="/">Work with us</a>
            </li>
          </ul>
        </section> */}
        <section className="column">
          <h5 className="footer-title">{t`Our company`}</h5>
          <ul className="footer-ul">
            <li className="footer-li">
              <a
                href={companyInformation?.work_with_us}
                target="_blank"
                rel="noreferrer"
              >{t`Work with us`}</a>
            </li>
            <li className="footer-li">
              <a
                style={{ cursor: "pointer" }}
                href={companyInformation?.brand_presentation}
              >{t`Brand presentation`}</a>
            </li>
          </ul>
        </section>

        <section className="column">
          <h5 className="footer-title">{t`Countries`}</h5>
          <ul className="footer-ul">
            <li className="footer-li">
              <a href="/">International</a>
            </li>
            <li className="footer-li">
              <a href="/">Lebanon</a>
            </li>
            <li className="footer-li">
              <a href="/">Jordan</a>
            </li>
            <li className="footer-li">
              <a href="/">UAE</a>
            </li>
          </ul>
        </section>

        <section className="column">
          <h5 className="footer-title">{t`Legal terms and conditions`}</h5>
          <ul className="footer-ul">
            <li className="footer-li">
              <a href="/cookie-policy">{t`Cookie Policy`}</a>
            </li>
            <li className="footer-li">
              <a href="/terms-conditions">{t`Terms & Conditions`}</a>
            </li>
          </ul>
          <Box display={"flex"} alignItems={"center"}>
            <a
              href={companyInformation?.app_store_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={appStore}
                alt="download on app store"
                style={{ width: 100 }}
              />
            </a>
            <a
              href={companyInformation?.google_play_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={googlePlay}
                alt="download on google play"
                style={{ width: 100, height: 50 }}
              />
            </a>
          </Box>
        </section>
      </main>
      {/* <Grid container justifyContent={"center"}>
        <Grid item xs={11} md={9.8}>
          <Box display={"flex"}>
            <Instagram />
          </Box>
        </Grid>
      </Grid> */}
      <div className="footer-main-bottom">
        <Box display={"flex"} alignItems={"center"}>
          <IconButton color="inherit">
            <a
              href={companyInformation?.facebook_url}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookTwoTone style={{ color: "#000" }} />
            </a>
          </IconButton>
          <IconButton color="inherit">
            <a
              href={companyInformation?.insta_url}
              target="_blank"
              rel="noreferrer"
            >
              <Instagram style={{ color: "#000" }} />
            </a>
          </IconButton>
        </Box>
        <hr style={{ marginBottom: 10, color: "rgba(0,0,0,0.2)" }} />
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={3}
        >
          <p className="p-text-bold">{t`We accept`}</p>

          <img
            src="https://logowik.com/content/uploads/images/153_visa.jpg"
            alt="Visa card"
            style={{ objectFit: "cover", width: 40 }}
          />
          <img
            src="https://w7.pngwing.com/pngs/962/794/png-transparent-mastercard-credit-card-mastercard-logo-mastercard-logo-love-text-heart.png"
            alt="Visa card"
            style={{ objectFit: "cover", width: 40 }}
          />

          <FaApplePay size={30} />

          <FaGooglePay size={30} />
        </Box>
      </div>
    </footer>
  );
}

export default Footer;
